import "./App.css";
import Home from "./pages/home";
import Nav from "./components/nav";
import Home2 from "./pages/home2";
import Home3 from "./pages/home3";
import About from "./pages/about";
import Form from "./pages/form";
import Footer from "./components/footer";

function App() {
  return (
    <div className="scroll-smooth">
      <Nav />
      <div id="home" />
      <Home />
      <Home2 />
      <Home3 />
      <div id="about" />
      <About />
      <div id="contact" />
      <Form />
      <Footer />
    </div>
  );
}

export default App;

import React from "react";

const Home2 = () => {
  const openInstagram = () => {
    window.open("https://www.instagram.com/hope.co.in", "_blank");
    // or use window.location.href = "https://www.instagram.com/your-instagram-handle"; to open in the same tab
  };
  return (
    <div className="w-full flex flex-col items-center justify-center max-sm:gap-[2vh] max-sm:h-[110vh]">
      <div className="h-[40vh] w-full bg-white flex items-center justify-center gap-[3vw] max-sm:h-[65vw] max-sm:flex-col max-sm:gap-[5vw]">
        <h1 className="text-[5vw] font-black max-sm:text-[13vw] max-sm:w-4/5 max-sm:text-center max-sm:leading-[15vw]">
          Time for a change
        </h1>
        <button onClick={openInstagram} className="w-[9vw] h-[4vw] border border-black transition-all duration-200 hover:bg-black hover:text-white hover:font-medium text-[1.1vw] max-sm:w-[33vw] max-sm:h-[13vw] max-sm:text-[3.5vw] max-sm:mt-[2vw]">
          Join Us
        </button>
      </div>
      <div className="w-4/5 h-[70vh] text-center">
        <iframe
          src="https://eyes.nasa.gov/apps/earth/"
          width="100%"
          height="100%"
          frameborder="0"
          title="NASA Earth App"
        ></iframe>
      </div>
    </div>
  );
};

export default Home2;

import React, { useState, useEffect } from "react";
import Logo from "../assets/logo.svg";
import Ham from "../assets/hamburger.svg";
import Cross from "../assets/cross.svg";

const Nav = () => {

  const openInstagram = () => {
    window.open("https://www.instagram.com/hope.co.in", "_blank");
    // or use window.location.href = "https://www.instagram.com/your-instagram-handle"; to open in the same tab
  };

  const [isOpen, setIsOpen] = useState(false);
  const updateState = () => {
    setIsOpen(!isOpen);
  };

  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  // Function to handle scroll behavior

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        // Scrolling down
        setShowNavbar(false);
        setIsOpen(false);
      } else {
        // Scrolling up
        setShowNavbar(true);
      }

      setLastScrollY(currentScrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`w-4/5 h-[5vw] bg-white rounded-full fixed left-[10vw] z-[5] flex justify-between items-center transition-all duration-300 ${
        showNavbar
          ? "top-[3vw] max-sm:top-[7vw]"
          : "top-[-6vw] max-sm:top-[-16vw]"
      } shadow-md max-sm:h-[15vw]`}
    >
      <img
        src={Logo}
        className="h-[3.3vw] ml-[3vw] max-sm:h-[9vw] max-sm:ml-[7vw]"
        alt="logo"
      />
      <img
        onClick={updateState}
        src={isOpen ? Cross : Ham}
        className={`hidden max-sm:block mr-[6vw] ${
          isOpen ? "h-[10vw]" : "h-[4.5vw"
        }`}
        alt="hamburger"
      />
      <ul className="flex gap-[2vw] text-[1vw] mr-[4vw] max-sm:hidden">
        <a
          href="#home"
          className="transition duration-200 hover:text-[#49603B75] hover:underline"
        >
          Home
        </a>
        <a
          href="#about"
          className="transition duration-200 hover:text-[#49603B75] hover:underline"
        >
          About
        </a>
        <a
          href="#contact"
          className="transition duration-200 hover:text-[#49603B75] hover:underline"
        >
          Contact
        </a>
        <p
          onClick={openInstagram}
          className="transition duration-200 hover:text-[#49603B75] underline hover:cursor-pointer font-semibold"
        >
          Join Us
        </p>
      </ul>

      {showNavbar && (
        <div
          className={`w-4/5 hidden fixed max-sm:flex bg-[#ffffff63] top-[14vw] rounded-b-[10vw] p-[10vw] pt-[15vw] -z-10 overflow-hidden transition-all duration-500 ${
            isOpen ? "h-[100vw]" : "h-0 opacity-0"
          } flex-col gap-[5vw] text-[3.5vw] font-medium`}
        >
          <a href="#home">
            <button className="w-full h-[15vw] bg-white rounded-full">
              Home
            </button>
          </a>
          <a href="#about">
            <button className="w-full h-[15vw] bg-white rounded-full">
              About
            </button>
          </a>
          <a href="#contact">
            <button className="w-full h-[15vw] bg-white rounded-full">
              Contact
            </button>
          </a>
          <button onClick={openInstagram} className="w-full h-[15vw] bg-white rounded-full">
            Join Us
          </button>
        </div>
      )}
    </div>
  );
};

export default Nav;

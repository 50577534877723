import React from "react";
import Design from "../assets/design.svg";

const About = () => {
  return (
    <div className="flex flex-col w-full items-center">
      <div className="flex items-center w-full justify-center gap-[2vw] max-sm:gap-[3vw]">
        <div className="bg-black h-[1px] w-[25vw] max-sm:w-[15vw]" />
        <h1 className="text-[5vw] font-black max-sm:text-[12vw]">About Us</h1>
        <div className="bg-black h-[1px] w-[25vw] max-sm:w-[15vw]" />
      </div>
      <p className="w-[65vw] mt-[3vw] text-[1.1vw] text-center max-sm:text-[3.1vw] max-sm:w-[70vw] max-sm:mt-[6vw]">
        It was about 150 years ago that industrialization gained traction. The
        period when we shifted from an Agrarian society to an industrial one.
        This modernization was human centric, with human needs and demands at
        its core. The sole focus was human welfare and development. Little to no
        thought went into the possible effects this revolution would have on our
        environment. The amount of waste generated post – modernization is so
        huge that waste management cannot keep pace with the present scenario.
        The health of our planet has deteriorated drastically. We have neglected
        our responsibility towards the planet that was given to us. And that
        needs to change. We have to step up. We have to reverse what we have
        caused. And we at HOPE strive to do just that! The world is literally
        hanging on by a thread. It’s falling. The planet is ailing. It's not too
        late, there is still hope. And as long as there is hope, there will
        always be a way.
      </p>
      <p className="w-[65vw] mt-[2.5vw] text-[1.1vw] text-center max-sm:text-[3.1vw] max-sm:w-[70vw] max-sm:mt-[5vw]">
        HOPE endeavours to restore the health of the planet. Prove to the world
        that waste is not wasteful. It is one of our main pillars in building a
        sustainable and healthy planet. Where useful materials from waste are
        segregated and put in circulation for various processes. This will not
        only reduce the amount of waste that is disposed off but also ensure
        that we have enough raw materials for all the numerous industrial
        processes. The earth has limited supply and creating this cycle of use
        and reuse will warrant that we don’t run short on resources. The main
        aim being to restore the balance in nature which at the very moment is
        tipping very dangerously, on the verge of destruction if nothing is
        done.
      </p>
      <img src={Design} className="h-[2.4vw] my-[5vw] max-sm:h-[6vw] max-sm:my-[12vw]" alt="design" />
    </div>
  );
};

export default About;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB62gW81GQysgsAFAIAQjeT3rcEoouxHe8",
  authDomain: "hope-10f8b.firebaseapp.com",
  projectId: "hope-10f8b",
  storageBucket: "hope-10f8b.appspot.com",
  messagingSenderId: "637505811249",
  appId: "1:637505811249:web:f246810e97431718713610",
  measurementId: "G-PTZQ2T3X68"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export default app;
import React from "react";
import Logo from "../assets/logo2.svg";

const Footer = () => {
  const openInstagram = () => {
    window.open("https://www.instagram.com/hope.co.in", "_blank");
    // or use window.location.href = "https://www.instagram.com/your-instagram-handle"; to open in the same tab
  };
  return (
    <div className="w-full h-[20vw] bg-[#83582E] shadow-footer flex justify-between item-center px-[10vw] max-sm:h-[120vw] max-sm:mt-[15vh] max-sm:flex-col max-sm:items-center max-sm:justify-center max-sm:gap-[10vw]">
      <div className="flex h-full w-1/2 items-center gap-[2vw] max-sm:justify-center max-sm:gap-[7vw] max-sm:h-max">
        <img src={Logo} alt="logo" className="w-[12vw] max-sm:w-[33vw]" />
        <h1 className="text-white text-[2.5vw] w-[17vw] font-black leading-[3.3vw] max-sm:text-[6.5vw] max-sm:leading-[8vw] max-sm:w-[45vw]">
          We Are Wasting Our Waste!
        </h1>
      </div>
      <div className="flex flex-col justify-center gap-[1.3vw] items-center font-light text-[#FFF2D9] mr-[3vw] max-sm:mr-0 max-sm:gap-[3vw]">
        <a href="#home" className="underline text-[1vw] max-sm:text-[3vw]">Home</a>
        <a href="#about" className="underline text-[1vw] max-sm:text-[3vw]">About Us</a>
        <a href="#contact" className="underline text-[1vw] max-sm:text-[3vw]">Contact</a>
        <button onClick={openInstagram} className="w-[9vw] h-[3.5vw] border border-[#FFF2D9] text white transition-all duration-200 hover:bg-[#FFF2D9] hover:text-[#83582E] hover:font-medium text-[1.1vw] max-sm:w-[27vw] max-sm:h-[10vw] max-sm:text-[3vw] max-sm:mt-[2vw]">
          Join Us
        </button>
      </div>
    </div>
  );
};

export default Footer;

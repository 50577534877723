import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import '../App.css';

const Form = () => {

  const [showPopup, setShowPopup] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    city: "",
    occupation: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "requests"), formData);

      setFormData({
        name: "",
        email: "",
        mobile: "",
        city: "",
        occupation: "",
        message: "",
      });

      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);

    } catch (error) {
      console.error("Error Adding the Document: ", error);
    }
  };
  return (
    <div className="flex flex-col items-center w-[80vw] ml-[10vw] bg-[#a7c957a6] rounded-[2vw] mt-[5vw] shadow-md mb-[8vw] pb-[8vw] max-sm:pb-[15vw]">
        <h1 className="text-[5vw] text-[#49603B] font-black mt-[6vw] max-sm:mt-[12vw] drop-shadow-[5px_5px_5px_rgba(255,255,255,0.75)] max-sm:text-[9vw]">Contact Us</h1>
        <p className="text-center text-[#49603B] text-[1.1vw] w-[37vw] mt-[1vw] max-sm:text-[2.5vw]">For tour enquiries ,or any questions, please use the form below and we will get back to you.</p>
        <div className="bg-[#49603B] w-[45vw] max-sm:w-[50vw] my-[3vw] max-sm:my-[7vw] p-[2vw] max-sm:p-[4vw] text-white text-[1.1vw] max-sm:text-[2.5vw] outline outline-[.7vw] max-sm:outline-[1.5vw] outline-[#49603B50] ">Do you wish to conserve and create a better future our planet earth?If yes, please join us! It’s free, there are no obligations, and you’ll receive access to all our communication information to our activities, events, campaigns, etc. We send out email updates a few times and you can always unsubscribe. To join our network, please fill out the form below!</div>

      <form
        onSubmit={handleSubmit}
        className="w-[46vw] flex flex-col mt-[2vw] gap-[2vw] items-center"
      >
        <div className="mb-4">
          <input
            type="text"
            name="name"
            required
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            className="w-[46vw] h-[4.5vw] p-3 px-6 rounded-xl border-b-[.3vw] border-[#49603B] focus:outline-none text-[1.1vw] focus:ring-2 focus:ring-[#5f7c4c] max-sm:w-[52vw] max-sm:h-[10vw] max-sm:text-[2.5vw]"
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="w-[46vw] h-[4.5vw] p-3 px-6 rounded-xl border-b-[.3vw] border-[#49603B] focus:outline-none text-[1.1vw] focus:ring-2 focus:ring-[#5f7c4c] max-sm:w-[52vw] max-sm:h-[10vw] max-sm:text-[2.5vw]"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="mobile"
            required
            value={formData.mobile}
            onChange={handleChange}
            placeholder="Mobile"
            className="w-[46vw] h-[4.5vw] p-3 px-6 rounded-xl border-b-[.3vw] border-[#49603B] focus:outline-none text-[1.1vw] focus:ring-2 focus:ring-[#5f7c4c] max-sm:w-[52vw] max-sm:h-[10vw] max-sm:text-[2.5vw]"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="city"
            required
            value={formData.city}
            onChange={handleChange}
            placeholder="City"
            className="w-[46vw] h-[4.5vw] p-3 px-6 rounded-xl border-b-[.3vw] border-[#49603B] focus:outline-none text-[1.1vw] focus:ring-2 focus:ring-[#5f7c4c] max-sm:w-[52vw] max-sm:h-[10vw] max-sm:text-[2.5vw]"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="occupation"
            required
            value={formData.occupation}
            onChange={handleChange}
            placeholder="Occupation"
            className="w-[46vw] h-[4.5vw] p-3 px-6 rounded-xl border-b-[.3vw] border-[#49603B] focus:outline-none text-[1.1vw] focus:ring-2 focus:ring-[#5f7c4c] max-sm:w-[52vw] max-sm:h-[10vw] max-sm:text-[2.5vw]"
          />
        </div>
        <div className="mb-4">
          <textarea
            name="message"
            required
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            className="w-[46vw] h-[4.5vw] p-3 px-6 rounded-xl border-b-[.3vw] border-[#49603B] focus:outline-none text-[1.1vw] focus:ring-2 focus:ring-[#5f7c4c] max-sm:w-[52vw] max-sm:h-[10vw] max-sm:text-[2.5vw]"
            rows="4"
          />
        </div>
        <button
          type="submit"
          className="w-[13vw] h-[4vw] bg-[#49603B] text-white text-[1.2vw] font-medium p-3 rounded-lg hover:bg-[#6b8d57] transition-colors shadow-md max-sm:w-[30vw] max-sm:text-[2.5vw] max-sm:h-[10vw]"
        >
          Submit
        </button>
      </form>

      {showPopup && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white opacity-80 text-black text-center p-[2vw] max-sm:p-[5vw] rounded-lg shadow-2xl popup-animation">
          <p className="text-[1.2vw] max-sm:text-[3vw] font-semibold">Message sent successfully!</p>
        </div>
      )}
    </div>
  );
};

export default Form;

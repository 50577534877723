import React from "react";

const Home3 = () => {
  return (
    <div className="h-[80vh] w-full my-[10vw] overflow-hidden max-sm:my-[25vw]">
      <video
        src="https://cdn.pixabay.com/video/2023/04/11/158349-816637197_tiny.mp4"
        autoPlay
        loop
        muted
        className="w-full h-[79vh] relative object-cover"
      />
      <div className="h-[80vh] w-full bg-gradient-to-b from-white via-[#ffffff00] to-white gap-[2vw] max-sm:gap-[6vw] -translate-y-[79.5vh] flex flex-col justify-center items-center text-center">
        <h1 className="text-[#49603B] text-[5vw] font-black drop-shadow-[5px_5px_5px_rgba(255,255,255,0.75)] max-sm:text-[14vw] max-sm:w-4/5 max-sm:leading-[17vw]">
          We are Wasting Our Waste
        </h1>
        <div className="w-4/5 bg-[#ffffffa6] p-[2vw] text-[1.2vw] max-sm:text-[3.5vw] max-sm:p-[6vw]">
          With more than 7 billion people on the planet, we are producing
          ever-greater quantities of waste that are damaging the environment.
          The planet itself will survive, but that may not be the case for our
          descendants, nor for the other species with which we share the planet.
          But on the brighter side with the help of technologies, the waste we
          generate can actually be used as a resource and thereby scale down the
          demand of our natural resource. Ultimately, whether waste will
          constitute a problem or a resource all depends on how we manage it.
        </div>
      </div>
    </div>
  );
};

export default Home3;
